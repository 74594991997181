import { MongoQuery } from '@casl/ability';

import { CaslAction, CaslSubject, Permission } from '@ppg/core/enums';
import { Actions, Subjects, UserInfo } from '@ppg/core/models';

export const getPermission = (
  permission: Permission,
  user: UserInfo,
): { action: Actions; subject: Subjects; condition?: MongoQuery }[] => {
  const PERMISSION_MAP: { [key in Permission]: { action: Actions; subject: Subjects; condition?: MongoQuery }[] } = {
    // Customer
    [Permission.VIEW_CUSTOMERS]: [{ action: CaslAction.READ, subject: CaslSubject.CUSTOMER }],
    [Permission.MANAGE_CUSTOMERS]: [{ action: CaslAction.MANAGE, subject: CaslSubject.CUSTOMER }],
    // Job
    [Permission.VIEW_JOBS]: [{ action: CaslAction.READ, subject: CaslSubject.JOB, condition: { isActive: true } }],
    [Permission.VIEW_INACTIVE_JOBS]: [{ action: CaslAction.READ, subject: CaslSubject.JOB }],
    [Permission.CREATE_JOBS]: [{ action: CaslAction.CREATE, subject: CaslSubject.JOB }],
    [Permission.MANAGE_JOBS]: [
      {
        action: CaslAction.CREATE,
        subject: CaslSubject.JOB,
        condition: { isActive: true, salesRep: user.email?.toLowerCase() },
      },
      {
        action: CaslAction.CREATE,
        subject: CaslSubject.JOB,
        condition: { isActive: true, secondarySalesRep: user.email?.toLowerCase() },
      },
    ],
    [Permission.MANAGE_ALL_JOBS]: [{ action: CaslAction.MANAGE, subject: CaslSubject.JOB }],
    [Permission.COPY_JOBS]: [{ action: CaslAction.COPY, subject: CaslSubject.JOB }],
    [Permission.REQUEST_ACCESS_ALL_JOBS]: [{ action: CaslAction.REQUEST_ACCESS, subject: CaslSubject.JOB }],
    // Specified Job Item
    [Permission.MANAGE_SPECIFIED_JOB_ITEMS]: [{ action: CaslAction.MANAGE, subject: CaslSubject.SPECIFIED_JOB_ITEM }],
    [Permission.VIEW_SPECIFIED_JOB_ITEMS]: [{ action: CaslAction.READ, subject: CaslSubject.SPECIFIED_JOB_ITEM }],
    // Fulfillment Contact
    [Permission.ADD_ALL_FULFILLMENT_CONTACTS]: [
      { action: CaslAction.CREATE, subject: CaslSubject.FULFILLMENT_CONTACT },
    ],
    [Permission.ADD_FULFILLMENT_CONTACTS_FOR_DEMO_JOBS]: [
      {
        action: CaslAction.CREATE,
        subject: CaslSubject.FULFILLMENT_CONTACT,
        condition: { jobType: 'DEMO' },
      },
    ],
    [Permission.ADD_CUSTOMER_FULFILLMENT_CONTACTS]: [
      {
        action: CaslAction.CREATE,
        subject: CaslSubject.FULFILLMENT_CONTACT,
        condition: { customerKey: user.customerKey },
      },
    ],
    [Permission.ADD_FULFILLMENT_CONTACTS_AS_SALES_REP]: [
      {
        action: CaslAction.CREATE,
        subject: CaslSubject.FULFILLMENT_CONTACT,
        condition: { salesRep: user.email?.toLowerCase() },
      },
      {
        action: CaslAction.CREATE,
        subject: CaslSubject.FULFILLMENT_CONTACT,
        condition: { secondarySalesRep: user.email?.toLowerCase() },
      },
    ],
    [Permission.ADD_FULFILLMENT_CONTACTS_AS_JOB_USER]: [
      {
        action: CaslAction.CREATE,
        subject: CaslSubject.FULFILLMENT_CONTACT,
      },
    ],
    [Permission.DELETE_ALL_FULFILLMENT_CONTACTS]: [
      { action: CaslAction.DELETE, subject: CaslSubject.FULFILLMENT_CONTACT },
    ],
    [Permission.DELETE_FULFILLMENT_CONTACTS_FOR_DEMO_JOBS]: [
      {
        action: CaslAction.DELETE,
        subject: CaslSubject.FULFILLMENT_CONTACT,
        condition: { jobType: 'DEMO' },
      },
    ],
    [Permission.DELETE_CUSTOMER_FULFILLMENT_CONTACTS]: [
      {
        action: CaslAction.DELETE,
        subject: CaslSubject.FULFILLMENT_CONTACT,
        condition: { customerKey: user.customerKey },
      },
    ],
    [Permission.DELETE_FULFILLMENT_CONTACTS_AS_SALES_REP]: [
      {
        action: CaslAction.DELETE,
        subject: CaslSubject.FULFILLMENT_CONTACT,
        condition: { salesRep: user.email?.toLowerCase() },
      },
      {
        action: CaslAction.DELETE,
        subject: CaslSubject.FULFILLMENT_CONTACT,
        condition: { secondarySalesRep: user.email?.toLowerCase() },
      },
    ],
    [Permission.DELETE_FULFILLMENT_CONTACTS_AS_JOB_USER]: [
      {
        action: CaslAction.DELETE,
        subject: CaslSubject.FULFILLMENT_CONTACT,
      },
    ],
    [Permission.EDIT_ALL_FULFILLMENT_CONTACTS]: [
      { action: CaslAction.UPDATE, subject: CaslSubject.FULFILLMENT_CONTACT },
    ],
    [Permission.EDIT_FULFILLMENT_CONTACTS_FOR_DEMO_JOBS]: [
      {
        action: CaslAction.UPDATE,
        subject: CaslSubject.FULFILLMENT_CONTACT,
        condition: { jobType: 'DEMO' },
      },
    ],
    [Permission.EDIT_CUSTOMER_FULFILLMENT_CONTACTS]: [
      {
        action: CaslAction.UPDATE,
        subject: CaslSubject.FULFILLMENT_CONTACT,
        condition: { customerKey: user.customerKey },
      },
    ],
    [Permission.EDIT_FULFILLMENT_CONTACTS_AS_SALES_REP]: [
      {
        action: CaslAction.UPDATE,
        subject: CaslSubject.FULFILLMENT_CONTACT,
        condition: { salesRep: user.email?.toLowerCase() },
      },
      {
        action: CaslAction.UPDATE,
        subject: CaslSubject.FULFILLMENT_CONTACT,
        condition: { secondarySalesRep: user.email?.toLowerCase() },
      },
    ],
    [Permission.EDIT_FULFILLMENT_CONTACTS_AS_JOB_USER]: [
      {
        action: CaslAction.CREATE,
        subject: CaslSubject.FULFILLMENT_CONTACT,
      },
    ],
    // Delivery Address
    [Permission.ADD_ALL_DELIVERY_ADDRESSES]: [{ action: CaslAction.CREATE, subject: CaslSubject.DELIVERY_ADDRESS }],
    [Permission.ADD_DELIVERY_ADDRESSES_FOR_DEMO_JOBS]: [
      {
        action: CaslAction.CREATE,
        subject: CaslSubject.DELIVERY_ADDRESS,
        condition: { jobType: 'DEMO' },
      },
    ],
    [Permission.ADD_CUSTOMER_DELIVERY_ADDRESSES]: [
      {
        action: CaslAction.CREATE,
        subject: CaslSubject.DELIVERY_ADDRESS,
        condition: { customerKey: user.customerKey },
      },
    ],
    [Permission.ADD_DELIVERY_ADDRESSES_AS_SALES_REP]: [
      {
        action: CaslAction.CREATE,
        subject: CaslSubject.DELIVERY_ADDRESS,
        condition: { salesRep: user.email?.toLowerCase() },
      },
      {
        action: CaslAction.CREATE,
        subject: CaslSubject.DELIVERY_ADDRESS,
        condition: { secondarySalesRep: user.email?.toLowerCase() },
      },
    ],
    [Permission.ADD_DELIVERY_ADDRESSES_AS_JOB_USER]: [
      { action: CaslAction.CREATE, subject: CaslSubject.DELIVERY_ADDRESS },
    ],
    [Permission.DELETE_ALL_DELIVERY_ADDRESSES]: [{ action: CaslAction.DELETE, subject: CaslSubject.DELIVERY_ADDRESS }],
    [Permission.DELETE_DELIVERY_ADDRESSES_FOR_DEMO_JOBS]: [
      {
        action: CaslAction.DELETE,
        subject: CaslSubject.DELIVERY_ADDRESS,
        condition: { jobType: 'DEMO' },
      },
    ],
    [Permission.DELETE_CUSTOMER_DELIVERY_ADDRESSES]: [
      {
        action: CaslAction.DELETE,
        subject: CaslSubject.DELIVERY_ADDRESS,
        condition: { customerKey: user.customerKey },
      },
    ],
    [Permission.DELETE_DELIVERY_ADDRESSES_AS_SALES_REP]: [
      {
        action: CaslAction.DELETE,
        subject: CaslSubject.DELIVERY_ADDRESS,
        condition: { salesRep: user.email?.toLowerCase() },
      },
      {
        action: CaslAction.DELETE,
        subject: CaslSubject.DELIVERY_ADDRESS,
        condition: { secondarySalesRep: user.email?.toLowerCase() },
      },
    ],
    [Permission.DELETE_DELIVERY_ADDRESSES_AS_JOB_USER]: [
      {
        action: CaslAction.DELETE,
        subject: CaslSubject.DELIVERY_ADDRESS,
      },
    ],
    [Permission.EDIT_ALL_DELIVERY_ADDRESSES]: [{ action: CaslAction.UPDATE, subject: CaslSubject.DELIVERY_ADDRESS }],
    [Permission.EDIT_DELIVERY_ADDRESSES_FOR_DEMO_JOBS]: [
      {
        action: CaslAction.UPDATE,
        subject: CaslSubject.DELIVERY_ADDRESS,
        condition: { jobType: 'DEMO' },
      },
    ],
    [Permission.EDIT_CUSTOMER_DELIVERY_ADDRESSES]: [
      {
        action: CaslAction.UPDATE,
        subject: CaslSubject.DELIVERY_ADDRESS,
        condition: { customerKey: user.customerKey },
      },
    ],
    [Permission.EDIT_DELIVERY_ADDRESSES_AS_SALES_REP]: [
      {
        action: CaslAction.UPDATE,
        subject: CaslSubject.DELIVERY_ADDRESS,
        condition: { salesRep: user.email?.toLowerCase() },
      },
      {
        action: CaslAction.UPDATE,
        subject: CaslSubject.DELIVERY_ADDRESS,
        condition: { secondarySalesRep: user.email?.toLowerCase() },
      },
    ],
    [Permission.EDIT_DELIVERY_ADDRESSES_AS_JOB_USER]: [
      {
        action: CaslAction.UPDATE,
        subject: CaslSubject.DELIVERY_ADDRESS,
      },
    ],
    [Permission.MANAGE_DELIVERY_ADDRESSES]: [{ action: CaslAction.MANAGE, subject: CaslSubject.DELIVERY_ADDRESS }],
    [Permission.VIEW_DELIVERY_ADDRESSES]: [{ action: CaslAction.READ, subject: CaslSubject.DELIVERY_ADDRESS }],
    // Order
    [Permission.CREATE_ORDERS]: [{ action: CaslAction.CREATE, subject: CaslSubject.ORDER }],
    [Permission.VIEW_ORDERS]: [{ action: CaslAction.READ, subject: CaslSubject.ORDER }],
    [Permission.VIEW_STORE_ORDERS]: [
      { action: CaslAction.READ, subject: CaslSubject.ORDER_SETTINGS, condition: { showStoreOrders: true } },
    ],
    [Permission.VIEW_STORE_ORDER_DETAILS]: [{ action: CaslAction.READ, subject: CaslSubject.STORE_ORDER_DETAILS }],
    [Permission.VIEW_EXPORT_ORDER_HISTORY]: [{ action: CaslAction.READ, subject: CaslSubject.EXPORT_ORDER_HISTORY }],
    [Permission.CREATE_ORDERS_DASHBOARD]: [{ action: CaslAction.CREATE, subject: CaslSubject.ORDER_DASHBOARD }],
    // User
    [Permission.MANAGE_ALL_USERS]: [{ action: CaslAction.MANAGE, subject: CaslSubject.USER }],
    [Permission.MANAGE_CUSTOMER_USERS]: [
      { action: CaslAction.MANAGE, subject: CaslSubject.USER, condition: { customerKey: user.customerKey } },
    ],
    [Permission.VIEW_ALL_USERS]: [{ action: CaslAction.READ, subject: CaslSubject.USER }],
    [Permission.VIEW_CUSTOMER_USERS]: [
      { action: CaslAction.READ, subject: CaslSubject.CUSTOMER_USERS, condition: { customerKey: user.customerKey } },
    ],
    // Profile Notification Preference
    [Permission.VIEW_PROFILE_NOTIFICATION_PREFERENCES]: [
      {
        action: CaslAction.READ,
        subject: CaslSubject.GLOBAL_SETTINGS,
        condition: { userProfileEnabled: true },
      },
    ],
    // Price
    [Permission.VIEW_PRICES]: [{ action: CaslAction.READ, subject: CaslSubject.PRICE }],
    // Account
    [Permission.IMPORT_EXTERNAL_ACCOUNTS]: [{ action: CaslAction.IMPORT, subject: CaslSubject.EXTERNAL_ACCOUNT }],
    [Permission.VIEW_EXTERNAL_ACCOUNTS]: [{ action: CaslAction.READ, subject: CaslSubject.EXTERNAL_ACCOUNT }],
    // Import
    [Permission.MANAGE_IMPORTS]: [{ action: CaslAction.MANAGE, subject: CaslSubject.IMPORT }],
    [Permission.VIEW_IMPORTS]: [{ action: CaslAction.READ, subject: CaslSubject.IMPORT }],
    // Custom Color
    [Permission.VIEW_CUSTOM_COLOR_TINTBASE]: [
      { action: CaslAction.READ, subject: CaslSubject.GLOBAL_SETTINGS, condition: { customColorBaseEnabled: true } },
    ],
    // Admin Page
    [Permission.VIEW_ADMIN_PAGE]: [{ action: CaslAction.READ, subject: CaslSubject.ALL }],
    // View Payment Methods
    [Permission.VIEW_PAYMENT_METHODS]: [{ action: CaslAction.READ, subject: CaslSubject.PAYMENT_METHODS }],
    // View Pay My Bill
    [Permission.VIEW_PAY_MY_BILL]: [{ action: CaslAction.READ, subject: CaslSubject.PAY_MY_BILL }],
  };

  return PERMISSION_MAP[permission];
};
